<template>
  <div style="border: 1px solid black;padding: 5px 8px">
    <div class="progress-bar">
      <div class="progress" :style="{ width: progressValue + '%' }">
        <div class="progress-highlight">
          <div class="highlightBox" v-for="(item, index) in items" :key="index"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Progress } from 'vant-green';
Vue.use(Progress);
export default {
  name: 'CustomProgressBar',
  props: {
    progressValue: {
      type: Number,
      validator: value => value >= 0 && value <= 100
    }
  },
  watch: {
    progressValue: {
      handler(val, old) {
        this.items = Math.ceil(val * 20 / 100)
      },
    }
  },
  data() {
    return {
      items: Math.ceil(this.progressValue * 20 / 100)
    }
  }
}
</script>

<style scoped>
.progress-bar {
  width: 100%;
  height: 18px;
  background-color: white; /* 进度条背景色 */
  border-radius: 0; /* 去除圆角 */
  overflow: hidden;
}

.progress {
  height: 100%;
  position: relative;
  transition: width 0.3s ease;
}

.progress-highlight {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  /*border-width: 1px;*/
  justify-content: space-between;
}
.highlightBox{
  width: 0.8rem;
  height: 100%;
  background-color: #54DB1E;
  padding-left: 2px;
  border-width: 0.7px; /* 设置边框宽度 */
  border-style: solid; /* 设置边框样式为实线 */
  border-color: rgba(51, 51, 51, 0.4); /* 设置边框颜色 */
}
</style>
